import React from "react";
import Layout from "../components/layout/Layout";
import { graphql } from "gatsby";

const ProductValidation = ({ data, location }) => {
  const { items } = data.contentfulPageShop;
  return (
    <Layout>
      <div>
        {items.map((item, i) => {
          return (
            <>
              <button
                className={"snipcart-add-item"}
                data-item-name={`${item.productName}`}
                data-item-id={item.contentful_id}
                data-item-url={`https://youthmissionoutreach.org/product-validation`}
                data-item-price={item.price}
                data-item-shippable={item.category === "Ship" ? true : false}
                data-item-description={item.description.description}
              />
            </>
          );
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    contentfulPageShop {
      items {
        price
        contentful_id
        productName
        description {
          description
        }
        category
      }
    }
  }
`;

export default ProductValidation;
